import { InjectionToken } from "@angular/core";

import { GrantRoleType, IMenu } from "src/app/core/ITypes";

export const NAV_MENU = new InjectionToken("menu.config");

export const Menu: IMenu[] = [
  {
    slug: "HOME",
    label: "Home",
    labelKey: "MENU.MN000",
    url: "/dashboard",
    icon: "fa fa-home",
    visible: true,
  },
  {
    slug: "PRODUCT",
    label: "Products",
    labelKey: "MENU.MN001",
    url: "/dashboard/product",
    icon: "fab fa-product-hunt",
    visible: true,
  },
  {
    slug: "ORDER",
    label: "Orders",
    labelKey: "MENU.MN002",
    url: "/dashboard/order",
    icon: "fa fa-shopping-cart",
    visible: true,
  },
  {
    slug: "SUPPLIER",
    label: "Suppliers",
    labelKey: "MENU.MN003",
    url: "/dashboard/supplier",
    icon: "fa fa-truck",
    visible: false,
  },
  {
    slug: "UNIT",
    label: "Units",
    labelKey: "MENU.MN004",
    url: "/dashboard/unit",
    icon: "fa fa-cube",
    visible: false,
  },
  {
    slug: "DISPLAYGROUP",
    label: "Display Groups",
    labelKey: "MENU.MN005",
    url: "/dashboard/display-group",
    icon: "fa fa-sitemap",
    visible: false,
  },
  {
    slug: "CUSTOMER",
    label: "Customers",
    labelKey: "MENU.MN006",
    url: "/dashboard/customer",
    icon: "fa fa-users",
    visible: true,
  },
  {
    slug: "USER",
    label: "Users",
    labelKey: "MENU.MN007",
    url: "/dashboard/user",
    icon: "fa fa-user",
    visible: true,
    accessBy: [GrantRoleType.ADMIN],
  },
  {
    slug: "PROJECT",
    label: "Projects",
    labelKey: "MENU.MN008",
    url: "/dashboard/project",
    icon: "fa fa-list-alt",
    visible: false,
  },
  {
    slug: "CAMPAIGN",
    label: "Campaigns",
    labelKey: "MENU.MN009",
    url: "/dashboard/campaign",
    icon: "fa fa-bullhorn",
    visible: false,
  },
  {
    slug: "VENDOR",
    label: "Vendor",
    labelKey: "MENU.MN010",
    url: "/dashboard/vendor",
    icon: "fa fa-industry",
    visible: false,
  },
  /* {
    slug: "PROMO",
    label: "Promo codes",
    labelKey: "MENU.MN020",
    url: "/dashboard/promo",
    icon: "fa fa-tag",
    visible: true,
  }, */
];

export const BREADCRUMB = {
  PRODUCT: {
    detail: "MENU.MN011",
    alias: {
      detail: "PRODUCT.DETAIL",
      edit: "PRODUCT.EDIT",
    },
  },
  SUPPLIER: {
    detail: "MENU.MN012",
    alias: {
      detail: "SUPPLIER.DETAIL",
      edit: "SUPPLIER.EDIT",
    },
  },
  ORDER: {
    detail: "MENU.MN013",
    alias: {
      detail: "ORDER.DETAIL",
      reclaim: "ORDER.RECLAIM",
      picklist: "ORDER.PICKLIST",
    },
  },
  CUSTOMER: {
    detail: "MENU.MN014",
  },
  DISPLAY_GROUP: {
    detail: "MENU.MN015",
  },
  PROJECT: {
    detail: "MENU.MN016",
  },
  VENDOR: {
    detail: "MENU.MN017",
    alias: {
      detail: "VENDOR.DETAIL",
      edit: "VENDOR.EDIT",
    },
  },
  PROFILE: {
    user: "MENU.MN018",
    edit: "MENU.MN019",
  },
  COMMON: {
    edit: "Edit",
  },
  PROMO: {
    detail: "MENU.MN020",
    editDetail: "MENU.MN021",
    alias: {
      detail: "PROMO.DETAIL",
      edit: "PROMO.EDIT",
    },
  },
};
