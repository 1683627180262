import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";

import { CommonsModule } from "../commons/commons.module";

import { LayoutComponent } from "./layout.component";

import { MyCookieService } from "../core/services/cookie.service";
import { AuthGuardService } from "src/app/core/services";
import { RolePermissionGuard } from "../core/guards";

import { GrantRoleType } from "../core/ITypes";

export const routes: Routes = [
  {
    path: "",
    // canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "product",
    data: {
      breadcrumb: "GLOBAL.G025",
    },
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./product/product.module").then((m) => m.ProductModule),
  },
  {
    path: "order",
    data: {
      breadcrumb: "MENU.MN002",
    },
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./order/order.module").then((m) => m.OrderModule),
  },
  {
    path: "supplier",
    data: {
      breadcrumb: "GLOBAL.G043",
    },
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./supplier/supplier.module").then((m) => m.SupplierModule),
  },
  {
    path: "unit",
    data: {
      breadcrumb: "MENU.MN004",
    },
    canActivate: [AuthGuardService],
    loadChildren: () => import("./unit/unit.module").then((m) => m.UnitModule),
  },
  {
    path: "display-group",
    data: {
      breadcrumb: "GLOBAL.G046",
    },
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./display-group/display-group.module").then(
        (m) => m.DisplayGroupModule
      ),
  },
  {
    path: "promo",
    data: {
      breadcrumb: "MENU.MN020",
    },
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./promo/promo.module").then((m) => m.PromoModule),
  },
  {
    path: "customer",
    data: {
      breadcrumb: "GLOBAL.G051",
    },
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./customer/customer.module").then((m) => m.CustomerModule),
  },
  {
    path: "project",
    data: {
      breadcrumb: "MENU.MN008",
    },
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./project/project.module").then((m) => m.ProjectModule),
  },
  {
    path: "user",
    data: {
      breadcrumb: "GLOBAL.G045",
      accessBy: [GrantRoleType.ADMIN],
    },
    canActivate: [AuthGuardService, RolePermissionGuard],
    loadChildren: () => import("./user/user.module").then((m) => m.UserModule),
  },
  {
    path: "vendor",
    data: {
      breadcrumb: "GLOBAL.G044",
    },
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./vendor/vendor.module").then((m) => m.VendorModule),
  },
  {
    path: "campaign",
    data: {
      breadcrumb: "MENU.MN009",
    },
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./campaign/campaign.module").then((m) => m.CampaignModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule, CommonsModule],
  exports: [LayoutComponent],
  providers: [MyCookieService],
  declarations: [LayoutComponent],
})
export class LayoutModule {}
