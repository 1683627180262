import { Injectable } from "@angular/core";

import {
  ApiService,
  HttpQueryService,
  LocaleService,
} from "src/app/core/services";

import { IQueryParams } from "src/app/core/ITypes";
import { VendorType } from "src/app/layout/vendor/entity";
import { promoFormSchema } from "src/app/layout/promo/write/schema";
import { isNumeric } from "src/app/commons/utils";
import { IPromoCode } from "src/app/layout/promo/ITypes";
import { lastValueFrom } from "rxjs";

@Injectable()
export class PromoService {
  constructor(
    private apiService: ApiService,
    private httpQueryService: HttpQueryService,
    private localeService: LocaleService
  ) {}

  getFormSchema() {
    try {
      const { status, result } = promoFormSchema;

      if (status === 200 && result && result.properties) {
        const components = result.properties.components;

        return components;
      }
    } catch (error) {
      throw error;
    }
  }

  async save(data: IPromoCode) {
    try {
      const { id: promoID, ...payload } = data;
      const payloadEntity = {
        properties: payload,
        id: promoID || undefined,
      };
      let requestUrl = "/promo-code/",
        response,
        responseMsg;

      if (data.id) {
        response = await lastValueFrom(
          this.apiService.patch(payloadEntity, requestUrl)
        );
        responseMsg = this.localeService.trans("NOTIFY.NT010");
      } else {
        response = await lastValueFrom(
          this.apiService.post(payloadEntity, requestUrl)
        );
        responseMsg = this.localeService.trans("NOTIFY.NT009");
      }

      const { status, result } = response;

      if (status === 200 && result) {
        const props = result?.properties;
        if (props) {
          props["id"] = result.id;
        }
        return {
          data: props,
          message: responseMsg,
        };
      }
    } catch (error) {
      throw error;
    }
  }

  async getPromos(queryParams: IQueryParams) {
    try {
      const limit = queryParams.limit;
      const skip = queryParams.offset;
      const searchTerm = queryParams.search?.trim();
      const search = Object.assign(
        {},
        isNumeric(searchTerm) ? { code: searchTerm } : { code: searchTerm }
      );
      const direction = queryParams.direction;
      const order = queryParams.order;
      const filter = queryParams.filter;
      const querySet = { limit, skip, direction, order, search, filter };
      const queryString = this.httpQueryService.serialize(querySet);

      let apiEndpoint = `/promo-code?${queryString}`;

      const { status, result, meta } = await this.apiService
        .get(apiEndpoint)
        .toPromise();

      if (status === 200 && result) {
        const r = result.map((r) => {
          const props = r.properties;

          props.titleType = VendorType.findTitle(props.type);

          return { id: r.id, ...props };
        });

        return { result: r, meta };
      }
    } catch (error) {
      throw error;
    }
  }

  async getPromo(id: string) {
    try {
      const { status, result } = await this.apiService
        .getById(null, `/${id}`)
        .toPromise();

      if (status === 200 && result) {
        const props = result.properties;
        return props;
      }
    } catch (error) {
      throw error;
    }
  }

  async isPromoExist(vendor: {
    key: string;
    value: number | string;
    id?: string;
  }): Promise<{ status: boolean; msg: string }> {
    try {
      const params = {
        limit: 50,
        filter: [{ key: vendor.key, value: vendor.value }],
      };

      const { result } = await this.getPromos(params);
      const vendors = result || [];
      let status = result.length > 0;
      let msgLiteral = this.localeService.trans("ERRORS.ER010");

      if (status) {
        let foundVendor = vendors.find((item) => {
          return item[vendor.key] == vendor.value;
        });

        //It will trigger in [UPDATE] case where id is existed
        if (foundVendor && vendor.id) {
          if (foundVendor.id == vendor.id) {
            return { status: false, msg: "" };
          }
        }

        let validateMsg = {
          vendorId: { value: foundVendor, label: "ID" },
          code: { value: foundVendor, label: "Code" },
        };

        const selectedMsg = validateMsg[vendor.key];

        if (selectedMsg.value) {
          msgLiteral = msgLiteral.replace(
            "{msg}",
            `${selectedMsg.value[vendor.key]}`
          );
        }
      }

      return { status, msg: status ? msgLiteral : "" };
    } catch (e) {
      return { status: false, msg: "" };
    }
  }
}
