export const promoFormSchema = {
  status: 200,
  result: {
    properties: {
      name: null,
      label: null,
      description: null,
      components: [
        // Details
        {
          id: null,
          key: "2391869b48d04693845fbbe1841ee622",
          ref: "promoHeader",
          type: "panel",
          required: false,
          label: "Promo",
          labelKey: "GLOBAL.G085",
          unique: false,
          default: null,
          tooltip: null,
          description: null,
          data: null,
          hidden: false,
          protected: false,
          components: [
            {
              key: "title",
              type: "textfield",
              labelKey: "GLOBAL.G001",
              default: null,
              validate: true,
              validators: [{ callback: "required" }],
            },
            {
              key: "startDate",
              type: "date",
              labelKey: "GLOBAL.G065",
              default: null,
              validate: true,
              validators: [{ callback: "required" }],
              format_type: "YYYY-MM-DD",
              watchValidity: ["expiryDate"],
              allowPastDates: true
            },
            {
              key: "expiryDate",
              type: "date",
              labelKey: "GLOBAL.G074",
              default: null,
              validate: true,
              validators: [{ callback: "checkEndDate" }],
              format_type: "YYYY-MM-DD",
              allowPastDates: true
            },
            {
              key: "type",
              type: "dropdown",
              labelKey: "GLOBAL.G075",
              default: "fixed",
              clearable: false,
              data: {
                values: [
                  {
                    value: "fixed",
                    labelKey: "GLOBAL.G076",
                    label: "Fixed",
                  },
                  {
                    value: "percentage",
                    labelKey: "GLOBAL.G077",
                    label: "Percentage",
                  },
                ],
              },
            },
          ],
        },
        // Code
        {
          id: null,
          key: "2391869b48d04693845fbbe1841ee624",
          ref: "promoCode",
          type: "panel",
          labelKey: "GLOBAL.G059",
          components: [
            {
              key: "code",
              type: "textfield",
              labelKey: "GLOBAL.G078",
              default: null,
              validate: true,
              validators: [{ callback: "required" }],
              normalized: true,
              pattern: "^[a-zA-Z0-9_\\+\\-\\!\\?\\%]$",
            },
            {
              key: "value",
              type: "number",
              labelKey: "GLOBAL.G079",
              default: null,
              validate: true,
              validators: [{ callback: "required" }],
            },
          ],
        },
        // Conditions
        {
          id: null,
          key: "2391869b48d04693845fbbe1841ee623",
          ref: "promoConditions",
          type: "panel",
          labelKey: "GLOBAL.G080",
          components: [
            {
              key: "maxUses",
              type: "number",
              dataType: "int",
              labelKey: "GLOBAL.G081",
              default: null,
              validate: true,
              validators: [
                { callback: "required" },
                { callback: "greaterThanZero" },
              ],
              watchValidity: ["maxUsesUser"],
            },
            {
              key: "maxUsesUser",
              type: "number",
              dataType: "int",
              labelKey: "GLOBAL.G082",
              default: null,
              validators: [{ callback: "betweenMaxTotal" }],
            },
            {
              key: "minCartAmount",
              type: "number",
              labelKey: "GLOBAL.G083",
              default: null,
            },
          ],
        },
      ],
    },
  },
};
